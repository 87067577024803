import React, {useState} from 'react';
import './Dashboard.css';
import {useNavigate} from 'react-router-dom';
import {useRef} from 'react';
import {useEffect} from 'react';
import {FaUsers, FaChartLine, FaUserPlus, FaCalendarWeek, FaCalendarDay, FaEye, FaEyeSlash} from 'react-icons/fa';


function Dashboard() {
    const [activeSection, setActiveSection] = useState('home');
    const fileInputRef = useRef(null);
    const formData = new FormData();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [userStats, setUserStats] = useState({
        total_users: 0,
        new_users_last_month: 0,
        monthly_growth: '',
        new_users_last_week: 0,
        weekly_growth: '',
        new_users_last_day: 0
    });
    const [users, setUsers] = useState([]);
    const [page] = useState(1);
    const [pageSize] = useState(10);
    const server_url = "https://api.escoladeultrassom.com"


    // new user add
    const [newUserData, setNewUserData] = useState({
        name: '',
        email: '',
        password: '',
        role: 'user' // Default role is user
    });

    const handleNewUserInputChange = (event) => {
        const {name, value} = event.target;
        setNewUserData({
            ...newUserData,
            [name]: value
        });
    };

    const createNewUser = async (event) => {
        event.preventDefault();

        const accessToken = localStorage.getItem('accessToken'); // Assuming 'accessToken' is stored in local storage
        try {
            const response = await fetch(server_url + '/admin/create-user/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(newUserData)
            });

            const data = await response.json();
            if (response.ok) {
                alert(data.message); // Display success message
                // Optionally clear form or handle further logic
            } else {
                console.error('Failed to create user');
            }
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    // Password Update
    const [passwordData, setPasswordData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [warning, setWarning] = useState('');

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setPasswordData({
            ...passwordData,
            [name]: value
        });
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown => !passwordShown);
    };

    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmPasswordShown => !confirmPasswordShown);
    };

    const updatePassword = async (event) => {
        event.preventDefault();
        if (passwordData.password !== passwordData.confirmPassword) {
            setWarning('Passwords do not match!');
            return;
        }
        setWarning('');

        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from local storage
        try {
            const response = await fetch(server_url + '/user/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    name: passwordData.name,
                    email: passwordData.email,
                    password: passwordData.password
                })
            });

            const data = await response.json();
            if (response.ok) {
                alert(data.message); // Show success message
            } else {
                console.error('Failed to update user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };


    useEffect(() => {

        const accessToken = localStorage.getItem('accessToken'); // Assuming 'access_token' is the key

        const fetchData = async () => {
            try {
                const response = await fetch(server_url + '/admin/user-stats/', {
                    method: 'GET', // Make sure it's a GET request
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserStats(data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchUsers = async () => {
            try {
                const response = await fetch(server_url + '/admin/get-users/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data);
                } else {
                    console.error('Failed to fetch users');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchData();
        fetchUsers();


    }, [page, pageSize]);

    const deleteUser = async (email) => {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
        try {
            // Construct URL with email included
            const url = server_url + `/admin/delete-user/${email}`;

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.detail); // Display success message
                setUsers(users.filter(user => user.email !== email)); // Update the local state to remove the user
            } else {
                console.error('Failed to delete user', data.detail);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };


    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        navigate('/');
    };


    if (fileInputRef.current && fileInputRef.current.files) {
        Array.from(fileInputRef.current.files).forEach(file => {
            formData.append('files', file);
        });
    }


    const updateChatbot = async () => {
        const formData = new FormData();
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            alert("Access token is missing. Please login or sign up again.");
            return;
        }

        selectedFiles.forEach(file => {
            formData.append('files', file);
        });

        try {
            const response = await fetch(server_url + '/update_model/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            await response.json();
            alert('Chatbot updated successfully.');
            // Optionally reset the selected files after successful upload
            setSelectedFiles([]);
        } catch (error) {
            alert('Failed to update chatbot. Please try again.');
        }
    };

    const handleUpdateFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const removeFile = (index) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
    };


    let navigate = useNavigate(); // Initialize the useNavigate hook

    const renderContent = () => {
        switch (activeSection) {


            case 'chatbot3':
                return (
                    <div className="chatbot-update-container">
                        <h1> Update Chatbot Knowledge base </h1>
                        <p>Enhance the intelligence of Investment Chatbot with additional information.</p>
                        <div className="update-form">
                            <input type="file" multiple onChange={handleUpdateFileChange} className="file-input"/>
                            <ul className="file-list">
                                {selectedFiles.map((file, index) => (
                                    <li key={index}>
                                        {file.name}
                                        <button onClick={() => removeFile(index)} className="delete-btn">X</button>
                                    </li>
                                ))}
                            </ul>
                            <button onClick={updateChatbot} className="submit-btn">Update</button>
                        </div>
                    </div>
                );


            case 'userView':
                return (
                    <div>
                        <h1 className="title">Investment Chatbot Users</h1>
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Authentication Method</th>
                                    <th>Created At</th>
                                    <th>Delete User</th>
                                </tr>
                                </thead>
                                <tbody>
                                {users.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role}</td>
                                        <td>{user.auth_method}</td>
                                        <td>{new Date(user.created_at).toLocaleString()}</td>
                                        <td>
                                            <button onClick={() => deleteUser(user.email)} className="delete-btn">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                );


            case 'settings':

                return (
                    <div className="setting-cards-container">
                        <div className="setting-card">
                            <h2 className="title">Add Users</h2>
                            <form onSubmit={createNewUser} className="create-user-form">
                                <input type="text" name="name" placeholder="Name" value={newUserData.name}
                                       onChange={handleNewUserInputChange} required/>
                                <input type="email" name="email" placeholder="Email" value={newUserData.email}
                                       onChange={handleNewUserInputChange} required/>
                                <div className="password-field">
                                    <input type={passwordShown ? 'text' : 'password'} name="password"
                                           placeholder="Password" value={newUserData.password}
                                           onChange={handleNewUserInputChange}/>
                                    <i onClick={togglePasswordVisiblity}>{passwordShown ? <FaEye/> : <FaEyeSlash/>}</i>
                                </div>
                                <select name="role" value={newUserData.role} onChange={handleNewUserInputChange}
                                        required>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                </select>
                                <button type="submit" className="create-button">Create</button>
                            </form>
                        </div>
                        <div className="setting-card">
                            <h2 className="title">Update Admin Password</h2>
                            <form onSubmit={updatePassword} className="create-user-form">
                                <input type="text" name="name" placeholder="Name" value={passwordData.name}
                                       onChange={handleInputChange}/>
                                <input type="email" name="email" placeholder="Email" value={passwordData.email}
                                       onChange={handleInputChange}/>
                                <div className="password-field">
                                    <input type={passwordShown ? 'text' : 'password'} name="password"
                                           placeholder="Password" value={passwordData.password}
                                           onChange={handleInputChange}/>
                                    <i onClick={togglePasswordVisiblity}>{passwordShown ? <FaEye/> : <FaEyeSlash/>}</i>
                                </div>
                                <div className="password-field">
                                    <input type={confirmPasswordShown ? 'text' : 'password'} name="confirmPassword"
                                           placeholder="Confirm Password" value={passwordData.confirmPassword}
                                           onChange={handleInputChange}/>
                                    <i onClick={toggleConfirmPasswordVisiblity}>{confirmPasswordShown ? <FaEye/> :
                                        <FaEyeSlash/>}</i>
                                </div>
                                {warning && <p className="warning">{warning}</p>}
                                <button type="submit" className="update-button">Update</button>
                            </form>
                        </div>
                    </div>
                );


            default:
                return (
                    <>
                        <div>
                            <h1>Ultrasonography Management Dashboard</h1>
                        </div>
                        <div className="card-container"
                             style={{display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center'}}>
                            <div className='card' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <FaUsers size={50}/>
                                <h3>Total Users</h3>
                                <p>{userStats.total_users}</p>
                            </div>
                            <div className='card' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <FaUserPlus size={50}/>
                                <h3>New Users Last Month</h3>
                                <p>{userStats.new_users_last_month}</p>
                            </div>
                            <div className='card' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <FaChartLine size={50}/>
                                <h3>Monthly Growth</h3>
                                <p>{userStats.monthly_growth}</p>
                            </div>
                            <div className='card' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <FaCalendarWeek size={50}/>
                                <h3>New Users Last Week</h3>
                                <p>{userStats.new_users_last_week}</p>
                            </div>
                            <div className='card' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <FaChartLine size={50}/>
                                <h3>Weekly Growth</h3>
                                <p>{userStats.weekly_growth}</p>
                            </div>
                            <div className='card' style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <FaCalendarDay size={50}/>
                                <h3>New Users Last Day</h3>
                                <p>{userStats.new_users_last_day}</p>
                            </div>
                        </div>
                    </>
                );
        }
    };


    return (
        <div className="dashboard-container">
            <aside className="sidebar">
                <h2>Menu</h2>
                <button onClick={() => setActiveSection('home')} className={activeSection === 'home' ? 'active' : ''}>
                    Home
                </button>
                <button onClick={() => setActiveSection('chatbot3')}
                        className={activeSection === 'chatbot3' ? 'active' : ''}>
                    Update Chatbot
                </button>


                <button onClick={() => setActiveSection('userView')}
                        className={activeSection === 'userView' ? 'active' : ''}>
                    View Users
                </button>
                <button onClick={() => setActiveSection('settings')}
                        className={activeSection === 'settings' ? 'active' : ''}>
                    Settings
                </button>
                <button onClick={handleLogout}>Logout</button>
            </aside>
            <main className="main-content">
                {renderContent()}
            </main>
        </div>
    );
}

export default Dashboard;
